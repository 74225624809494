<template>
  <div class="m-4 text-lg help-text">

    <h1>Kā lasīt portālā redzamos datus</h1>

    <p>Portālā redzamie dati par katru telpu tiek atjaunoti ar 2 minūšu intervālu.</p>
    <p>Lai iegūtu informāciju par gaisa kvalitātes izmaiņām, ir iespējams arī līkņu veidā aplūkot uzkrāto datu attēlojumu par noteiktu laika
      intervālu.</p>

    <h3>Portālā redzamie mērījumi atspoguļo gaisa kvalitāti trīs parametros:</h3>

    <ol>
      <li>a) CO<sub>2</sub> saturs gaisā ar precizitāti ne zemāku kā +/- 30 ppm (CO<sub>2</sub> mērvienība ppm jeb daļas no miljona) + 3% no mērījuma</li>
      <li>b) gaisa temperatūra ar precizitāti līdz +/- 2<sup>o</sup>C</li>
      <li>c) gaisa relatīvais mitrums ar precizitāti līdz +/- 5%</li>
    </ol>

    Informācija par portālā redzamajiem CO<sub>2</sub> mērījumiem:
    <ul class="list-info">
      <li>420 ppm ir svaiga gaisa CO<sub>2</sub> līmenis, zemāku līmeni dabiskā vidē nav iespējams sasniegt;</li>
      <li>420 - 1000 ppm - gaisa kvalitāte ir laba;</li>
      <li>1000 - 2000 ppm - gaisa kvalitāte pasliktinās;</li>
      <li>2000 - 3000 ppm - gaisa kvalitāte ir būtiski pasliktinājusies;</li>
      <li>virs 3000 ppm - telpā ir būtiski palielināts CO<sub>2</sub> līmenis, ilgstoša uzturēšanās šādā telpā var radīt draudus veselībai.</li>
      <li>virs 5000 ppm – telpā ir bīstama CO<sub>2</sub> koncentrācija.</li>
    </ul>

    <p><b>Lai gaisa kvalitātes mērītāji ikdienā rādītu korektus datus, telpas ir regulāri
      jāvēdina. Mērītāju kalibrācijas cikls ir 7.5 dienas. Ja minētajā periodā telpas netiek
      pilnvērtīgi izvēdinātas, mērītājs turpmākajā darbībā uzrādīs neprecīzu CO<sub>2</sub> līmeni.</b></p>

    <p><b>Ja jums rodas šaubas par gaisa kvalitātes mērītāju rādījumu precizitāti ilgākā periodā, aicinām sazināties ar piegādātāja pārstāvjiem.
      Iespējams, ka gaisa kvalitātes mērītājam ir tehniskas problēmas, kas novēršamas ierīču piegādātāja noteiktajā kārtībā.</b></p>

    <h3>Gaisa kvalitātes mērītāju precizitāte</h3>
    Gaisa kvalitātes mērījumiem ir noteikta šāda rādījumu precizitāte:
    <ol>
      <li>a) CO<sub>2</sub> saturs gaisā ar precizitāti ne zemāku kā +/- 30 ppm + 3% no mērījuma</li>
      <li>b) gaisa temperatūra ar precizitāti ne zemāku kā +/- 2<sup>o</sup>C</li>
      <li>c) gaisa relatīvais mitrums ar precizitāti ne zemāku kā +/- 5%.</li>
    </ol>

    <h3>Datu savākšanas iekārtas LED signāli:</h3>
    <p>Lai iekārta darbotos pilnvērtīgi, jādeg visiem trīs LED signāliem.</p>
    <ol>
      <li><span class="text-important">Power</span> – ja nedeg, iekārtai nav barošanas, kas tiek nodrošināta caur PoE, attiecīgi jāpārbauda komutāciju skapī vai iekārta pieslēgta PoE
        switcham, jeb pasīvam PoE inžektoram.
      </li>
      <li><span class="text-important">Ready</span> – ja deg, iekārta darbojas un saņem datus no gaisa kvalitātes mērītājiem, ja nedeg, jāmēģina uz 10 sekundēm atvienot tīkla vadu no
        iekārtas, indikatoram jāiedegas 5 minūšu laikā pēc barošanas atjaunošanas, ja tā nenotiek, jāpiesaka bojājums <a
          href="mailto:energoparvaldiba@mesh.lv">energoparvaldiba@mesh.lv</a>.
      </li>
      <li><span class="text-important">Connected</span> – Ja deg, iekārtai ir aktīvs interneta savienojums ar datu uzkrāšanas serveri. Ja nedeg, jāpārbauda interneta pieejamība.</li>
    </ol>

    <h3>Gaisa kvalitātes mērījumu atspoguļojums portālā</h3>

    <p>Portālā <span style="color: #1890ff">co2.mesh.lv</span> gaisa kvalitātes mērītāju rādījumi tiek attēloti divos veidos – Izglītības iestādes
      telpās veikto gaisa kvalitātes mērījumu atspoguļojums tiešsaistes režīmā ar 2 minūšu intervālu (attēls Nr.1) un gaisa kvalitātes mērījumu
      atspoguļojums laika intervālā līkņu veidā (attēls Nr.2).</p>
    <p>Ēku un telpas, kurās ir uzstādīti gaisa kvalitātes mērītāji portālā var atrast pēc izglītības iestādes nosaukuma, tās ēku un telpu nosaukumiem,
      kādus definējuši izglītības iestāžu pārstāvji.</p>

    <p><b>Attēls Nr.1 </b><br>
      <img src="@/assets/img/help1.png">
    </p>

    <p><b>Attēls Nr.2 </b><br>
      <img src="@/assets/img/help2.png">
    </p>

    <h3>Rīcība ar gaisa kvalitātes mērītājiem un datu savākšanas iekārtu</h3>

    <p>Gaisa kvalitātes mērītāju un datu savākšanas iekārtu atrašanās vieta ir saskaņota ar skolas pārstāvjiem. Saskaņotās vietas ir marķētas ar
      uzlīmēm, un ir veikta plānoto uzstādīšanas vietu fotofiksācija, lai iekārtas tiktu uzstādītas tām atbilstošajās vietās.</p>
    <p>Gaisa kvalitātes mērītāju pārvietošanu var veikt tikai sadarbībā ar piegādātāju, lai nodrošinātu, ka ierīce turpina darboties un tās veiktie
      mērījumi tiek saņemti portālā.</p>
    <p>Lai rīkotos ar gaisa kvalitātes mērītājiem un datu savākšanas iekārtu, ir jāiepazīstas ar iestādei izsniegto iekārtu lietošanas instrukciju
      (MESH_CBC_manual_v8.pdf);</p>

    <h3>Gaisa kvalitātes monitorings izglītības iestādē</h3>
    <p>Izglītības iestāde portālā <span style="color: #1890ff">co2.mesh.lv</span> var attālināti sekot datiem no telpās uzstādītajiem gaisa kvalitātes
      mērītājiem ar 2 minūšu intervālu (attēls Nr. 1)</p>

    <h3>Gaisa kvalitātes robežvērtības un indikatori</h3>
    <p>CO<sub>2</sub> saturam gaisā ir noteikta robežvērtība <span class="text-important">1000 ppm</span>.</p>
    <p>Ja CO<sub>2</sub> līmenis telpā <span class="text-important">pārsniedz 1000 ppm</span>, skaitli ietverošais kvadrāts portālā pakāpeniski maina krāsu no zaļas uz dzeltenu (attēls Nr. 3);</p>
    <p>Ja rādījums ir robežās no <span class="text-important">1000 – 2000 ppm</span>, skaitli ietverošais kvadrāts portālā pakāpeniski maina krāsu uz sarkanu, rādījumam pārsniedzot 1500
      ppm;</p>
    <p>Ja CO<sub>2</sub> līmenis telpā <span class="text-important">pārsniedz 2000 ppm</span>, telpā izvietotajam gaisa kvalitātes mērītājam parādās mirgojošs gaismas signāls, kas norāda uz
      nepieciešamību veikt telpas vēdināšanu;</p>
    <p>Ja CO<sub>2</sub> līmenis telpā <span class="text-important">pārsniedz 5000 ppm</span>, tad telpā izvietotajam gaisa kvalitātes mērītājam parādās skaņas signāls, kas norāda uz nepieciešamību
      nekavējoties atbrīvot telpu un veikt tās vēdināšanu;</p>

    <p><b>Attēls Nr.3 </b><br>
      <img src="@/assets/img/help3.png">
    </p>

    <p>Portālā ir iespējams arī aplūkot attiecīgās telpas datus laika intervālā, tādējādi izglītības iestāde un tās dibinātājs var iegūt pārskatu par
      gaisa kvalitāti telpā, ko var salīdzināt ar telpu noslodzes plānojumu un ventilācijas sistēmu darbības algoritmu (attēls Nr.2).</p>

    <p>Portālā ir redzams ne tikai CO<sub>2</sub> līmenis telpā, bet arī gaisa temperatūra un relatīvais gaisa mitrums telpā. Šie rādītāji ir jāvērtē
      kopsakarībās, jo tie visi veido kopējo gaisa kvalitāti telpā.</p>

    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: "Measurements"
}
</script>

<style scoped>
.help-text {
  max-width: 950px;
}

h1 {
  font-size: 24px;
  font-weight: bold;
}

h3 {
  font-size: 20px;
  font-weight: bold;
  color: #1890ff;
}

ol li {
  margin-left: 1.5rem;
}

.text-important {
  color: #689f38;
  font-weight: bold;
}
</style>
